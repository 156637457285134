import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, Trans, withTranslation } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../../localization/translations';
import PageMenu from '../../../../components/PageMenu/PageMenu';
import mortgageResourcesMenu from '../../../../data/page-menus/mortgage-resources';
import links from '../../../../data/customer-center/financialToolsLinks';
import ExternalLink from '../../../../components/ExternalLink/ExternalLink';
import Link from '../../../../components/Link/Link';
import { useLocaleContext } from '../../../../context/Locale';
import { getPathFromRouteKey } from '../../../../shared/utils/getPathFromRouteKey';

import './style.scss';

type Props = PageProps & WithTranslation;

const FinancialTools: React.FC<Props> = (props: Props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('financialTools.seo.title'),
    },
    page: {
      title: t('financialTools.heading'),
    },
    heroSection: {
      options: {
        heroImage: {
          fileName: 'hero-image-13.jpg',
          alt: 'Hero Image',
        },
        accentColor: HeroSectionColors.SKY_BLUE,
      },
    },
  };

  const generateOrgList = (list: any[]) => {
    return list.map((item, i) => {
      const link = links[item.linkTag];

      const anchor =
        link.type === 'internal' ? (
          <Link to={getPathFromRouteKey(link.path)} />
        ) : (
          <ExternalLink href={link[activeLocaleTag]} />
        );

      return (
        <div key={`${item.length}-${i}`} className="FinancialTools__body-generic-gap">
          <p>
            <Trans components={[anchor]}>{item.description}</Trans>
          </p>
          {item.list ? (
            <ul>
              {item.list.map((term: string) => (
                <li key={term}>{term}</li>
              ))}
            </ul>
          ) : null}
          {item.body ? <p>{item.body}</p> : null}
        </div>
      );
    });
  };

  return (
    <Layout options={layoutOptions} className="FinancialTools">
      <div className="grid-container ">
        <div className="row row-wrap FinancialTools__generic-gap">
          <section className="column column-60">
            <p className="FinancialTools__body-generic-gap">
              <Trans t={t} i18nKey="financialTools.body" />
            </p>
            {['pc', 'cba', 'fcac'].map((org) => (
              <div className="FinancialTools__generic-gap" key={org}>
                <h2 className="FinancialTools__org-list-heading">
                  {t(`financialTools.orgList.${org}.heading`)}
                </h2>
                {generateOrgList(
                  t(`financialTools.orgList.${org}.sectionList`, { returnObjects: true })
                )}
              </div>
            ))}
          </section>
          <aside className="column column-33 side-menu">
            <PageMenu
              menu={mortgageResourcesMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.mortgageResources`)}
            />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(FinancialTools);
